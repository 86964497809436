import React from "react"
import Layout from "../../components/layout/layout"
import StartDemo from "../../components/start-demo/start-demo"
import withAppContext from "../../contexts/app.context"
import styles from "../../styles/volterra-global-network.module.scss"
import { graphql, useStaticQuery, Link } from "gatsby"
import SEO from "../../components/seo"
import { ENV_CONSOLE_BASE_URL } from "../../utils/secrets"

const GlobalNetworkPage = () => {
  const queryResult = useStaticQuery(graphql`
    {
      seo: markdownRemark(
        fields: {
          relativePath: { eq: "products__volterra-global-network" }
          category: { eq: "seo" }
        }
      ) {
        frontmatter {
          metaTitle
          metaDescription
          metaKeywordList
          metaImageName
          metaTwImageName
        }
      }
    }
  `)

  const {
    seo: {
      frontmatter: {
        metaTitle,
        metaDescription,
        metaKeywordList,
        metaImageName,
        metaTwImageName,
      },
    },
  } = queryResult

  return (
    <Layout>
      <SEO
        title={metaTitle}
        pageUrl={"/products/volterra-global-network"}
        description={metaDescription}
        keywords={metaKeywordList}
        imageName={!!metaImageName ? `/previews/${metaImageName}` : undefined}
        twImageName={
          !!metaTwImageName ? `/previews/${metaTwImageName}` : undefined
        }
      />

      <section className={styles.header}>
        <div className="container volterra-container d-flex flex-column flex-lg-row">
          <div className={`row ${styles.header__wrapper}`}>
            <h1
              className={`${styles.header__title} ${styles.header__titleHighlighted}`}>
              Volterra Global Network
            </h1>
            <h2 className={styles.header__title}>
              Optimized for App-to-App Connectivity & Security
            </h2>
            <p className={styles.header__description}>
              Industry’s first app-to-app network that’s purpose built to
              connect and secure workloads across multi-cloud or edge with the
              flexibility to even host workloads for extreme performance
            </p>
            <div className={styles.header__cta}>
              <Link
                to={`${ENV_CONSOLE_BASE_URL}/signup/start`}
                aria-label="Start for Free"
                className={"btn btn-primary " + styles.header__btn}>
                Start for Free
              </Link>
            </div>
          </div>
          <div className={styles.header__imageWrapper}>
            <img
              src="/img/products/global-network.png"
              alt="Volterra Global Network"
              className={styles.header__image}
            />
          </div>
        </div>
      </section>

      <div className={styles.mainContainer}>
        <section className={styles.pops}>
          <div className={`container ${styles.popsContainer}`}>
            <div className={styles.pops__header}>
              <h2 className={styles.pops__headerTitle}>
                21 Global PoPs in 14 Metro Markets
              </h2>
              <p className={styles.pops__headerDescription}>
                Every Volterra PoP features multiple connections to Tier-1
                carriers, SaaS providers, direct connect to public cloud
                providers, and redundant 100Gbps and 400Gbps private connections
                to our other PoPs. This delivers high performance and
                carrier-grade reliability that is required for app-to-app and
                user-to-app traffic.{" "}
              </p>
            </div>

            <div className={styles.pops__body}>
              <div className={styles.pops__bodyImage}>
                <img src="/img/products/gn-pops.svg" alt="Pops" />
              </div>
            </div>

            <div className={`container ${styles.pops__regionContainer}`}>
              <div className="row list justify-content-center">
                <div className={styles.pops__regionWrapper}>
                  <div className={styles.pops__region}>
                    <h5 className={styles.pops__regionTitle}>North America</h5>
                    <div className={styles.pops__listWrapper}>
                      <ul className={styles.pops__list}>
                        <li className={styles.pops__listItem}>Seattle</li>
                        <li className={styles.pops__listItem}>San Jose</li>
                      </ul>
                      <ul className={styles.pops__list}>
                        <li className={styles.pops__listItem}>New York</li>
                        <li className={styles.pops__listItem}>Ashburn</li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className={`${styles.pops__region} ${styles.pops__regionSa}`}>
                    <h5 className={styles.pops__regionTitle}>South America</h5>
                    <div className={styles.pops__listWrapper}>
                      <ul className={styles.pops__list}>
                        <li className={styles.pops__listItem}>Brazil</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className={styles.pops__regionFiller} />
                <div className={styles.pops__regionWrapper}>
                  <div
                    className={
                      styles.pops__region + " " + styles.pops__regionRight
                    }>
                    <h5 className={styles.pops__regionTitle}>Europe</h5>
                    <div className={styles.pops__listWrapper}>
                      <ul className={styles.pops__list}>
                        <li className={styles.pops__listItem}>London</li>
                        <li className={styles.pops__listItem}>Paris</li>
                        <li className={styles.pops__listItem}>Lisbon</li>
                      </ul>
                      <ul className={styles.pops__list}>
                        <li className={styles.pops__listItem}>Amsterdam</li>
                        <li className={styles.pops__listItem}>Frankfurt</li>
                        <li className={styles.pops__listItem}>Madrid</li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className={
                      styles.pops__region + " " + styles.pops__regionRight
                    }>
                    <h5 className={styles.pops__regionTitle}>Asia</h5>
                    <div className={styles.pops__listWrapper}>
                      <ul className={styles.pops__list}>
                        <li className={styles.pops__listItem}>Singapore</li>
                        <li className={styles.pops__listItem}>Osaka</li>
                      </ul>
                      <ul className={styles.pops__list}>
                        <li className={styles.pops__listItem}>Tokyo</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className={styles.quotes}>
          <div className={`container ${styles.quotesContainer}`}>
            <div className={`${styles.quotes__wrapper}`}>
              <div className={styles.quotes__header}>
                <div className={styles.quotes__headerImage}>
                  <img src="/img/main/c_discount.png" alt="CDiscount" />
                </div>
              </div>

              <div className={styles.quotes__body}>
                <p>
                  “As an online marketplace the availability of our website is
                  critical, as every minute of downtime results in a significant
                  loss of revenue and a deterioration of our brand. We have
                  worked very closely with Volterra to maximize our resiliency
                  and security, as well as our user experience, and have been
                  able to build a complete application delivery network and
                  several security tools with their VoltMesh service.”
                </p>
              </div>

              <div className={styles.quotes__author}>
                <p className={styles.quotes__authorName}>Romain Broussard</p>
                <p className={styles.quotes__authorPosition}>
                  IT Director, Cdiscount
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className={styles.features}>
          <div className={`container ${styles.featuresContainer}`}>
            <div className="row d-flex flex-column flex-nowrap align-items-center">
              <h2 className={styles.features__title}>Full-stack Deployments</h2>
              <p className={styles.features__description}>
                The Volterra global network provides advanced networking,
                security and application delivery services across all its PoPs.
                These services are powered by the same VoltMesh and VoltStack
                SaaS software that we deploy in your public, private and edge
                cloud environments. We #dogfood it so you don’t have to!{" "}
              </p>
            </div>
            <div className="row justify-content-between">
              <div className={`col-12 col-xl-4 ${styles.features__wrapper}`}>
                <div className={styles.features__item}>
                  <img
                    className={styles.features__logo}
                    src="/img/products/sphere-icon.svg"
                    alt="Global Private Backbone"
                  />
                  <div className={styles.features__body}>
                    <h3 className={styles.features__name}>
                      Global Private Backbone
                    </h3>
                    <p className={styles.features__text}>
                      All Volterra PoPs are redundantly connected in each
                      continent through our own private backbone. This gives
                      customers the performance, reliability and control
                      required for app-to-app connectivity across hybrid or
                      multi-cloud environments.
                    </p>
                  </div>
                </div>
              </div>
              <div className={`col-12 col-xl-4 ${styles.features__wrapper}`}>
                <div className={styles.features__item}>
                  <img
                    className={styles.features__logo}
                    src="/img/products/tower-icon.svg"
                    alt="Cloud-Native Platform"
                  />
                  <div className={styles.features__body}>
                    <h3 className={styles.features__name}>
                      Cloud-Native Platform
                    </h3>
                    <p className={styles.features__text}>
                      All services are delivered using the Volterra scale-out
                      software stack, built using microservices, along with
                      automation by our SRE team. Using our distributed control
                      plane, customers’ changes can be deployed globally in less
                      than one second.
                    </p>
                  </div>
                </div>
              </div>
              <div className={`col-12 col-xl-4 ${styles.features__wrapper}`}>
                <div className={styles.features__item}>
                  <img
                    className={styles.features__logo}
                    src="/img/products/scale-icon.svg"
                    alt="Enterprise-Grade Services"
                  />
                  <div className={styles.features__body}>
                    <h3 className={styles.features__name}>
                      Enterprise-Grade Services
                    </h3>
                    <p className={styles.features__text}>
                      VoltMesh and VoltStack are deployed at every Volterra PoP,
                      enabling the Volterra network to provide routing, SD-WAN
                      termination, load balancing, multi-layer security,
                      globally-distributed app gateway, and Kubernetes-based app
                      orchestration.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className={styles.useCases}>
        <div className={`container ${styles.useCasesContainer}`}>
          <div className="row d-flex flex-column align-items-center">
            <h2 className={styles.useCases__title}>Infrastructure Services</h2>
            <p className={styles.useCases__description}>
              Our world-class SRE team and carrier-grade global infrastructure
              increases your NetOps productivity and simplifies operations of
              your applications in hybrid or multi-cloud.
            </p>
          </div>
          <div className="row justify-content-between">
            <div className={`col-12 col-lg-4 ${styles.useCases__item}`}>
              <img
                className={styles.useCases__logo}
                src="/img/products/directories-icon.svg"
                alt="Global Traffic Management"
              />
              <div className={styles.useCases__body}>
                <h3 className={styles.useCases__name}>
                  Global Traffic Management
                </h3>
                <p className={styles.useCases__text}>
                  We use our decades of networking software development and
                  operational experience to deliver the best user and app
                  experience. Armed with anycast, full-stack networking,
                  application metrics and a global real-time view of the
                  network, we route traffic based on your intent.
                </p>
              </div>
            </div>

            <div className={`col-12 col-lg-4 ${styles.useCases__item}`}>
              <img
                className={styles.useCases__logo}
                src="/img/products/lock-icon.svg"
                alt="High-Performance Access"
              />
              <div className={styles.useCases__body}>
                <h3 className={styles.useCases__name}>
                  High-Performance Access
                </h3>
                <p className={styles.useCases__text}>
                  Deploy private connections to cloud providers and data centers
                  across our high-speed private backbone for significant
                  app-to-app throughput gains. In addition, leverage private
                  peering with SaaS and cloud providers for fast and reliable
                  public API access.
                </p>
              </div>
            </div>

            <div className={`col-12 col-lg-4 ${styles.useCases__item}`}>
              <img
                className={styles.useCases__logo}
                src="/img/products/gear-icon.svg"
                alt="24x7 NOC and SOC"
              />
              <div className={styles.useCases__body}>
                <h3 className={styles.useCases__name}>24x7 NOC and SOC</h3>
                <p className={styles.useCases__text}>
                  Experienced SRE and operations teams lead our advanced
                  operations center. We deliver round the clock support to your
                  NetOps and DevOps teams to ensure maximum uptime, reliability
                  and performance for your apps, infrastructure and APIs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={`${styles.features} ${styles.multiLayer}`}>
        <div className={`container ${styles.featuresContainer}`}>
          <div className="row d-flex flex-column flex-nowrap align-items-center">
            <h2 className={styles.features__title}>
              Advanced Multi-Layer Security
            </h2>
            <p className={styles.features__description}>
              Advanced solution from our global network protects your
              distributed infrastructure and apps across hybrid clouds, multiple
              cloud providers and even enterprise edge or nomadic device
              deployments.{" "}
            </p>
          </div>
          <div className="row justify-content-between">
            <div className={`col-12 col-xl-4 ${styles.features__wrapper}`}>
              <div className={styles.features__item}>
                <img
                  className={styles.features__logo}
                  src="/img/products/bomb-icon.svg"
                  alt="Distributed DDoS"
                />
                <div className={styles.features__body}>
                  <h3 className={styles.features__name}>Distributed DDoS</h3>
                  <p className={styles.features__text}>
                    Customers get automatic security for their infrastructure
                    and apps across hybrid- or multi-cloud with global L3-L7+
                    DDoS protection. We deliver this capability using a
                    combination of hardware and the advanced software
                    capabilities of VoltMesh.
                  </p>
                </div>
              </div>
            </div>
            <div className={`col-12 col-xl-4 ${styles.features__wrapper}`}>
              <div className={styles.features__item}>
                <img
                  className={styles.features__logo}
                  src="/img/products/neural-icon.svg"
                  alt="Networking Security"
                />
                <div className={styles.features__body}>
                  <h3 className={styles.features__name}>Networking Security</h3>
                  <p className={styles.features__text}>
                    VoltMesh implements a network firewall and AI-based anomaly
                    detection to prevent attacks at the network-level. All
                    traffic between Volterra PoPs on our private backbone is
                    fully encrypted using IPSec and segmented using L3 VPNs.
                  </p>
                </div>
              </div>
            </div>
            <div className={`col-12 col-xl-4 ${styles.features__wrapper}`}>
              <div className={styles.features__item}>
                <img
                  className={styles.features__logo}
                  src="/img/products/security-icon.svg"
                  alt="Application Security"
                />
                <div className={styles.features__body}>
                  <h3 className={styles.features__name}>
                    Application Security
                  </h3>
                  <p className={styles.features__text}>
                    Using a combination of threat feeds, signature matching and
                    advanced algorithmic + AI/ML techniques, we protect customer
                    APIs and applications from attacks via the public internet
                    or within the enterprise.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.useCases}>
        <div className={`container ${styles.useCasesContainer}`}>
          <div className="row d-flex flex-column align-items-center">
            <h2 className={styles.useCases__title}>Physical Security</h2>
            <p className={styles.useCases__description}>
              All Volterra PoPs are built using the major colocation facilities
              of Equinix, Digital Realty and Telehouse, and are operated using
              strict procedures to ensure secure access to our platform.
            </p>
          </div>
          <div className="row justify-content-between">
            <div className={`col-12 col-lg-4 ${styles.useCases__item}`}>
              <img
                className={styles.useCases__logo}
                src="/img/products/webcam-icon.svg"
                alt="Video Sensors"
              />
              <div className={styles.useCases__body}>
                <h3 className={styles.useCases__name}>Video Sensors</h3>
                <p className={styles.useCases__text}>
                  IP camera feeds are continuously recorded and monitored at
                  every facility at the cage- and rack-level to ensure there is
                  no tampering with physical infrastructure.
                </p>
              </div>
            </div>

            <div className={`col-12 col-lg-4 ${styles.useCases__item}`}>
              <img
                className={styles.useCases__logo}
                src="/img/products/biometrics-icon.svg"
                alt="Biometrics"
              />
              <div className={styles.useCases__body}>
                <h3 className={styles.useCases__name}>Biometrics</h3>
                <p className={styles.useCases__text}>
                  Using fingerprint verification, only authorized Volterra
                  operations engineers are allowed access to our facilities
                  across the globe.
                </p>
              </div>
            </div>

            <div className={`col-12 col-lg-4 ${styles.useCases__item}`}>
              <img
                className={styles.useCases__logo}
                src="/img/products/human-verification-icon.svg"
                alt="Human Verification"
              />
              <div className={styles.useCases__body}>
                <h3 className={styles.useCases__name}>Human Verification</h3>
                <p className={styles.useCases__text}>
                  In addition to biometrics, access to any facility by a
                  Volterra operations engineer is validated based on a
                  government-issued identification document reviewed by trained
                  security staff.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <StartDemo />
    </Layout>
  )
}

export default withAppContext(GlobalNetworkPage)
